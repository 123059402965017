import React from "react";
import { SERVICE_TYPES } from "../../../enums/serviceTypes";
import "../SetHomeInfo/SetHomeInfo.scss";
import "./Voila.scss";

const Voila = ({ onClick, choosenService }) => {
  return (
    <>
      <h1>Voilá...</h1>
      <p className="step2p">
        För bästa service börjar vi alltid med ett kostnadsfritt startmöte för
        att anpassa städningen efter dina behov.
      </p>
      <h3>Ditt pris:</h3>
      <hr />
      <h2 className="price">
        {choosenService === SERVICE_TYPES.Hemstädning ? "239" : "425"}{" "}
        <span>kr/h</span>
      </h2>
      <h5 className="price-bottom">efter rut-avdrag</h5>
      <button className="step2-btn" onClick={onClick}>
        Boka kostnadsfritt startmöte
      </button>
    </>
  );
};

export default Voila;
