import React from "react";
import "./Steps.scss";
import { HiCheck } from "react-icons/hi";

const Steps = ({ step }) => {
  return (
    <div className="Steps">
      <div className="single-step active">
        <HiCheck />
      </div>
      <div className="line" />
      <div className={"single-step" + (step >= 2 ? " active" : "")}>
        {step > 2 ? <HiCheck /> : 2}
      </div>
      <div className="line" />
      <div className={"single-step" + (step >= 3 ? " active" : "")}>
        {step > 3 ? <HiCheck /> : 3}
      </div>
      {/* <div className="line" />
      <div className={"single-step" + (step >= 4 ? " active" : "")}>
        {step > 4 ? <HiCheck /> : 4}
      </div> */}
    </div>
  );
};

export default Steps;
