import axios from "axios";
import dayjs from "dayjs";

const API_URL = "https://backend.putso.nu/api/bildagroup/mail";

export const sendKontorsstadningEmail = (
  callback,
  placeSize,
  postNumber,
  date,
  time,
  howOften,
  firstName,
  lastName,
  email,
  mobileNumber,
  address,
  ort,
  notes
) => {
  axios
    .post(`${API_URL}`, {
      subject: `Kontorsstädning -  ${dayjs(date).format("DD.MM.YYYY")}`,
      html: `<div>
				<h2>New Kontorsstädning Service for ${dayjs(date).format("DD.MM.YYYY")}</h2>
				<p>Place size: ${placeSize}</p>
				<p>Date: ${dayjs(date).format("DD.MM.YYYY")}</p>
				<p>Time: ${time}</p>
				<p>How often cleaning: ${howOften}</p>
				<p>First name: ${firstName}</p>
				<p>Last name: ${lastName}</p>
				<p>Email: ${email}</p>
				<p>Mobile number: ${mobileNumber}</p>
				<p>Address: ${address}</p>
				<p>Post number: ${postNumber}</p>
				<p>Ort: ${ort}</p>
				<p>Notes: ${notes}</p>
			</div> `,
    })
    .then((response) => {
      callback();
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const sendFltyystadningEmail = (
  callback,
  placeSize,
  postNumber,
  date,
  leftKeyInOffice,
  firstName,
  lastName,
  email,
  mobileNumber,
  address,
  ort,
  personNumber,
  notes,
  price
) => {
  axios
    .post(`${API_URL}`, {
      subject: `Flyttstädning -  ${dayjs(date).format("DD.MM.YYYY")}`,
      html: `<div>
				<h2>New Flyttstädning Service for ${dayjs(date).format("DD.MM.YYYY")}</h2>
				<p>Place size: ${placeSize}</p>
				<p>Date: ${dayjs(date).format("DD.MM.YYYY")}</p>
				<p>Left key in office? : ${leftKeyInOffice ? "Yes" : "No"}</p>
				<p>First name: ${firstName}</p>
				<p>Last name: ${lastName}</p>
				<p>Email: ${email}</p>
				<p>Mobile number: ${mobileNumber}</p>
				<p>Person number: ${personNumber}</p>
				<p>Address: ${address}</p>
				<p>Post number: ${postNumber}</p>
				<p>Ort: ${ort}</p>
				<p>Notes: ${notes}</p>
				<h3>Price: ${price}</h3>
			</div> `,
    })
    .then((response) => {
      callback();
    });
};

export const sendStorstadningEmail = (
  callback,
  placeSize,
  postNumber,
  date,
  leftKeyInOffice,
  firstName,
  lastName,
  email,
  mobileNumber,
  address,
  ort,
  personNumber,
  notes,
  price
) => {
  axios
    .post(`${API_URL}`, {
      subject: `Storstädning -  ${dayjs(date).format("DD.MM.YYYY")}`,
      html: `<div>
				<h2>New Storstädning Service for ${dayjs(date).format("DD.MM.YYYY")}</h2>
				<p>Place size: ${placeSize}</p>
				<p>Date: ${dayjs(date).format("DD.MM.YYYY")}</p>
				<p>Left key in office? : ${leftKeyInOffice ? "Yes" : "No"}</p>
				<p>First name: ${firstName}</p>
				<p>Last name: ${lastName}</p>
				<p>Email: ${email}</p>
				<p>Mobile number: ${mobileNumber}</p>
				<p>Person number: ${personNumber}</p>
				<p>Address: ${address}</p>
				<p>Post number: ${postNumber}</p>
				<p>Ort: ${ort}</p>
				<p>Notes: ${notes}</p>
				<h3>Price: ${price}</h3>
			</div> `,
    })
    .then((response) => {
      callback();
    });
};
