import React from "react";
import "./Instructions.scss";

const Instructions = ({
  text1,
  text2,
  text3,
  heading1,
  heading2,
  heading3,
  bg,
  linkopingHeader,
  linkopingDescription,
}) => {
  const scrollToBooking = () => {
    const elem = document.getElementById("booking");
    elem.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  return (
    <>
      <div
        className="relative py-16 sm:py-24 lg:py-24"
        style={{ background: bg ? "#fafafa" : "#fff" }}
      >
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-6 lg:max-w-7xl">
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            {linkopingHeader
              ? linkopingHeader
              : "Vi gör det enkelt att boka städning"}
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            {linkopingDescription
              ? linkopingDescription
              : `Det ska inte vara krångligt, vi ser till att du kommer igång så
						snabbt och enkelt som möjligt.`}
          </p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              <div className="pt-6">
                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-6 bg-white rounded-full shadow-lg">
                        <p className="step-icon h-6 w-6 text-gray-800">1</p>
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {heading1 ? heading1 : "Berätta om ditt hem"}
                    </h3>
                    <p className="mt-5 text-base text-gray-500">{text1}</p>
                  </div>
                </div>
              </div>

              <div className="pt-6">
                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-6 bg-white rounded-full shadow-lg">
                        <p className="step-icon h-6 w-6 text-gray-800">2</p>
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {heading2 ? heading2 : "Välj en tid som passar dig"}
                    </h3>
                    <p className="mt-5 text-base text-gray-500">{text2}</p>
                  </div>
                </div>
              </div>

              <div className="pt-6">
                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-6 bg-white rounded-full shadow-lg">
                        <p className="step-icon h-6 w-6 text-gray-800">3</p>
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {heading3 ? heading3 : "Njut av ett rent hem"}
                    </h3>
                    <p className="mt-5 text-base text-gray-500">{text3}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-center">
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 ">
            <div className="inline-flex rounded-full shadow">
              <button
                onClick={scrollToBooking}
                className="inline-flex items-center justify-center px-8 py-4 border border-transparent text-base font-medium rounded-full text-white bg-indigo-600"
              >
                Beräkna ditt pris
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Instructions;
