import React, { useEffect } from "react";
import "./Step3.scss";
import { isMobile } from "react-device-detect";
import { AiOutlineInfoCircle } from "react-icons/ai";
import SubmitButton from "../SubmitButton/SubmitButton";
import BackButton from "../BackButton/BackButton";
import "react-calendar/dist/Calendar.css";

import Calendar from "react-calendar";
// import dayjs from "dayjs";
import { BsCheck, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import timesData from "../../../data/timesData.json";
import { STEP3 } from "../../../enums/stepsEnum";
import { SERVICE_TYPES } from "../../../enums/serviceTypes";
import { isSecondType } from "../../../helpers/isSecondType";

// dayjs.locale("sv");

// const disabledDates = [new Date("2021-5-28"), new Date("2021-5-26")];

const CalendarBack = () => (
  <div className="arrow arrow-back">
    <BsChevronLeft />
    <p>Tidigare</p>
  </div>
);

const CalendarNext = () => (
  <div className="arrow arrow-next">
    <p>Senare</p>
    <BsChevronRight />
  </div>
);

const Step3 = ({
  onSubmit,
  onBack,
  date,
  setDate,
  time,
  setTime,
  howOften,
  setHowOften,
  choosenService,
  leftKeyAtOffice,
  setLeyKeyAtOffice,
  emailCitySufix,
}) => {
  useEffect(() => {
    if (isMobile && date) {
      setTime(null);
      const elem =
        choosenService !== SERVICE_TYPES.Flyttstädning
          ? document.getElementById("ChooseTime")
          : document.getElementById("HowOften");
      // setTime(null);
      if (elem) {
        elem?.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
    //eslint-disable-next-line
  }, [date]);

  return (
    <>
      <div className="Step3 white-shadow">
        <h1 className="heading">{STEP3[choosenService].heading}</h1>
        {STEP3[choosenService].description && (
          <p className="description">{STEP3[choosenService].description}</p>
        )}
        <div className="calendar-wrapper">
          <Calendar
            onChange={setDate}
            nextLabel={<CalendarNext />}
            prevLabel={<CalendarBack />}
            value={date}
            minDate={new Date()}
            // tileDisabled={({ date, view }) =>
            //   view === "month" && // Block day tiles only
            //   disabledDates.some(
            //     (disabledDate) =>
            //       date.getFullYear() === disabledDate.getFullYear() &&
            //       date.getMonth() === disabledDate.getMonth() &&
            //       date.getDate() === disabledDate.getDate()
            //   )
            // }
          />
        </div>
        {date && !isSecondType(choosenService) && (
          <div className="choose-time" id="ChooseTime">
            <h1 className="heading">Välj tid</h1>
            <div className="times">
              {timesData.map((item, index) => (
                <button
                  className={"single-time" + (time === item ? " active" : "")}
                  onClick={() => setTime(item)}
                  key={index}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      {isSecondType(choosenService) ? (
        <div className="how-often white-shadow" id="HowOften">
          <h1 className="heading">Hur kommer vi in till bostaden?</h1>
          <div className="flex">
            <button
              className={"checkbox" + (leftKeyAtOffice ? " active" : "")}
              onClick={(e) => setLeyKeyAtOffice(true)}
            >
              <BsCheck />
            </button>
            <div>
              <h3>
                Jag lämnar nyckel på Bilda Groups kontor senast kl. 12 en
                arbetsdag innan
              </h3>
              <h6>Adress till närmaste kontor: </h6>
              <h5>Viktoriagatan 11, 41125 Göteborg</h5>
            </div>
          </div>
          <div className="flex">
            <button
              className={"checkbox" + (!leftKeyAtOffice ? " active" : "")}
              onClick={(e) => setLeyKeyAtOffice(false)}
            >
              <BsCheck />
            </button>
            <div>
              <h3>Jag är hemma och öppnar</h3>
            </div>
          </div>
          <div className="info">
            <AiOutlineInfoCircle />
            <p>
              Vi utför städningen och lämnar därefter nyckeln i din brevlåda om
              inget annat anges.{" "}
            </p>
          </div>
        </div>
      ) : (
        <div className="how-often white-shadow">
          <h1 className="heading">Hur ofta vill du ha städat?</h1>
          <p className="description">Vi städar som minst 3h per tillfälle.</p>
          <div className="buttons">
            <button
              className={howOften === "Varannan vecka" ? " active" : ""}
              onClick={() => setHowOften("Varannan vecka")}
            >
              Varannan vecka
            </button>
            <button
              className={howOften === "Varje vecka" ? " active" : ""}
              onClick={() => setHowOften("Varje vecka")}
            >
              Varje vecka
            </button>
          </div>
          <p className="bottom">
            <AiOutlineInfoCircle />
            Det populäraste valet är varannan vecka.
          </p>
        </div>
      )}
      <div className="buttons-flex">
        <BackButton text="Tillbaka" onClick={onBack} />
        {isSecondType(choosenService) ? (
          <SubmitButton text="Fortsätt" isValid={date} onClick={onSubmit} />
        ) : (
          <SubmitButton
            text="Fortsätt"
            isValid={date && time && howOften}
            onClick={onSubmit}
          />
        )}
      </div>
    </>
  );
};

export default Step3;
