export const minimumPrice = 1600;
export const biggerPlaceSqarePrice = 25;

export const FlyttstadningSqareMeters = {
  30: 1600,
  31: 1730,
  32: 3460,
  33: 1730,
  34: 1730,
  35: 1730,
  36: 1730,
  37: 1730,
  38: 1730,
  39: 1730,
  40: 1730,
  41: 1850,
  42: 1850,
  43: 1850,
  44: 1850,
  45: 1850,
  46: 1850,
  47: 1850,
  48: 1850,
  49: 1970,
  50: 1970,
  51: 1970,
  52: 1970,
  53: 1970,
  54: 1970,
  55: 2090,
  56: 2090,
  57: 2090,
  58: 2090,
  59: 2090,
  60: 2090,
};
