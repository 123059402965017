export const HOME_INFO = {
  Hemstädning: {
    heading: "Kom igång med Hemstädning",
    description:
      "Välj Bilda Group när du vill få det extra rent och snyggt hemma. Utan bindningstid, alltid till ett riktigt bra pris, alltid med kvalitetsgaranti.",
  },
  Flyttstädning: {
    heading: "Flyttstädning",
    description:
      "Vi på Bilda Group hjälper dig med flyttstädningen! Fönsterputs ingår och vi tar med all utrustning och städmaterial. Självklart med kvalitetsgaranti. Din köpare kommer att mötas av ett skinande hem och du kan ägna tid åt annat.",
  },
  Storstädning: {
    heading: "Dags för en storstädning?",
    description:
      "Professionell storstädning som hjälper dig att bli kär i ditt hem igen! Vi tar med all utrustning och miljövänligt städmaterial. Självklart med kvalitetsgaranti. ",
  },
  Kontorsstädning: {
    heading: "Låt oss ta hand om ditt kontor",
    description:
      "Vi på Bilda Group erbjuder lokalvård av högsta kvalité med skräddarsydda lösningar för en ren och trivsam arbetsmiljö. Låt ett proffs ta hand om städningen så att ni kan fokusera på det ni är bäst på. ",
  },
  Linkoping: {
    Hemstädning: {
      heading: "Hemstädning i Linköping",
      description:
        "Vi gör det enkelt att komma igång med hemstädning. Välj oss när du vill få det extra rent och snyggt hemma. Utan bindningstid, alltid till ett riktigt bra pris, alltid med kvalitetsgaranti.",
    },
    Flyttstädning: {
      heading: "Flyttstädning i Linköping",
      description:
        "Vi hjälper dig med en komplett flyttstädning i Linköping. Fönsterputs ingår och vi tar med all utrustning och städmaterial. Självklart med kvalitetsgaranti. Din köpare kommer att mötas av ett skinande hem och du kan ägna tid åt annat.",
    },
    Storstädning: {
      heading: "Storstädning i Linköping",
      description:
        "Professionell storstädning som hjälper dig att bli kär i ditt hem igen! Vi tar med all utrustning och miljövänligt städmaterial. Självklart med kvalitetsgaranti. ",
    },
    Kontorsstädning: {
      heading: "Låt oss ta hand om ditt kontor",
      description:
        "Vi på Bilda Group erbjuder lokalvård av högsta kvalité med skräddarsydda lösningar för en ren och trivsam arbetsmiljö. Låt ett proffs ta hand om städningen så att ni kan fokusera på det ni är bäst på. ",
    },
  },
};
