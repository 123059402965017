import React, { useEffect, useState } from "react";
import "./Booking.scss";
import { navigate } from "gatsby";
import { BsChevronUp } from "react-icons/bs";
import ChooseService from "./ChooseService/ChooseService";
import SetHomeInfo from "./SetHomeInfo/SetHomeInfo";
import CalculatingPrice from "./CalculatingPrice/CalculatingPrice";
import Steps from "./Steps/Steps";
// import Step2 from "./Step2/Step2";
import Ticket from "./Ticket/Ticket";
import OpenDropdown from "./OpenDropdown/OpenDropdown";
import TicketDropdownFlyt from "./TicketDropdown/TicketDropdownFlyt";
import TicketDropdownStors from "./TicketDropdown/TicketDropdownStors";
import Step3 from "./Step3/Step3";
import Step4 from "./Step4/Step4";
import Voila from "./Voila/Voila";
import SubmitButton from "./SubmitButton/SubmitButton";
import { SERVICE_TYPES, URL_SERVICE } from "../../enums/serviceTypes";
import { isSecondType } from "../../helpers/isSecondType";
import { calculateSqareMeterPrice } from "../../helpers/calculateSqareMeterPrice";
import Step2Popup from "./Step2Popup/Step2Popup";
import {
  sendFltyystadningEmail,
  sendKontorsstadningEmail,
  sendStorstadningEmail,
} from "../../helpers/emails";
import { withPrefix } from "gatsby";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { setBookingConfirm } from "../../store/actions/bookingConfirm";

const Booking = ({ finishBookingStep, setFinishBookingStep, ...props }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(props.firstStep);
  // const [finishBookingStep, setFinishBookingStep] = useState();
  const [choosenService, setChoosenService] = useState(props.choosenService);
  const [placeSize, setPlaceSize] = useState("");
  const [postNumber, setPostNumber] = useState("");
  // const [meetingType, setMeetingType] = useState("");
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [howOften, setHowOften] = useState();
  // const [additions, setAdditions] = useState({
  //   "Inglasad balkong/ uterum": 0,
  //   "Extra ugnsplåtar": 0,
  // });
  // const [fourSideBalcon, setFourSideBalcon] = useState(false);
  // const [twoSideBalcon, setTwoSideBalcon] = useState(false);
  const [ticketVisible, setTicketVisible] = useState(false);
  const [price, setPrice] = useState(0);

  //user
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [ort, setOrt] = useState("");
  const [personNumber, setPersonNumber] = useState();
  const [notes, setNotes] = useState("");
  const [dropdownPopupVisible, setDropdownPopupVisible] = useState(false);
  const [leftKeyAtOffice, setLeyKeyAtOffice] = useState(true);
  //eslint-disable-next-line
  const [emailCitySufix, setEmailCitySufix] = useState("");

  const navigateToSuccessPage = () => {
    dispatch(
      setBookingConfirm({
        type: choosenService,
        date: `${dayjs(date).format("DD.MM.YYYY.")} kl. ${time}`,
        address: address,
      })
    );
    navigate(`/booking-confirm/${URL_SERVICE[choosenService]}`);
  };

  useEffect(() => {
    if (loading) {
      const timeout = () => {
        setLoading(false);
        if (
          choosenService === SERVICE_TYPES.Flyttstädning ||
          choosenService === SERVICE_TYPES.Storstädning
        ) {
          setFinishBookingStep(2);
          props.setBookingActive(true);
        } else {
          setStep(3);
          props.setBookingActive(true);
        }
      };
      setTimeout(timeout, 2500);

      return () => clearTimeout(timeout);
    }
    //eslint-disable-next-line
  }, [loading]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {dropdownPopupVisible && (
        <Step2Popup
          choosenService={choosenService}
          onClose={() => setDropdownPopupVisible(false)}
          active={dropdownPopupVisible}
        />
      )}
      <div
        className={
          "Booking" +
          (finishBookingStep ? " finish" : "") +
          (props.bgType2 ? " bgType2" : "") +
          (props.bgType3 ? " bgType3" : "") +
          (props.bgType4 ? " bgType4" : "") +
          (props.linkopingBg ? " linkoping-bg" : "")
        }
        id="booking"
      >
        {!finishBookingStep && (
          <div className={"content" + (!finishBookingStep ? " padding-0" : "")}>
            {step === 1 && (
              <ChooseService
                setChoosenService={(service) => {
                  setChoosenService(service);
                  setStep(2);
                  props.setServiceChosen(true);
                  scrollToTop();
                }}
                emailCitySufix={emailCitySufix}
              />
            )}
            {!loading && step === 2 && (
              <SetHomeInfo
                choosenService={choosenService}
                placeSize={placeSize}
                setPlaceSize={setPlaceSize}
                postNumber={postNumber}
                setPostNumber={setPostNumber}
                submit={() => {
                  setLoading(true);
                  if (isSecondType(choosenService)) {
                    setPrice(
                      calculateSqareMeterPrice(placeSize, choosenService)
                    );
                  }
                  scrollToTop();
                }}
                emailCitySufix={emailCitySufix}
              />
            )}
            {loading && <CalculatingPrice />}
            {step === 3 && !loading && (
              <Voila
                onClick={() => {
                  setFinishBookingStep(2);
                  scrollToTop();
                }}
                choosenService={choosenService}
              />
            )}
          </div>
        )}
        {!loading && finishBookingStep >= 2 && (
          <div className="finish-booking">
            <div className="left">
              <Steps step={finishBookingStep} />
              <div className="step-content">
                {/* {finishBookingStep === 2 && (
                  <Step2
                    meetingType={meetingType}
                    setMeetingType={setMeetingType}
                    onSubmit={() => {
                      if (isMobile) {
                        props.setAbleToGoBack(true);
                      }
                      setFinishBookingStep(3);
                      scrollToTop();
                    }}
                    onBack={() => {
                      setStep(2);
                      setFinishBookingStep(null);
                    }}
                    choosenService={choosenService}
                    additions={additions}
                    setAdditions={setAdditions}
                    fourSideBalcon={fourSideBalcon}
                    setFourSideBalcon={setFourSideBalcon}
                    twoSideBalcon={twoSideBalcon}
                    setTwoSideBalcon={setTwoSideBalcon}
                    price={price}
                    setPrice={setPrice}
                    placeSize={placeSize}
                  />
                )} */}
                {finishBookingStep === 2 && (
                  <Step3
                    onSubmit={() => {
                      setFinishBookingStep(3);
                      scrollToTop();
                    }}
                    onBack={() => setFinishBookingStep(null)}
                    date={date}
                    setDate={setDate}
                    time={time}
                    setTime={setTime}
                    howOften={howOften}
                    setHowOften={setHowOften}
                    choosenService={choosenService}
                    price={price}
                    setPrice={setPrice}
                    leftKeyAtOffice={leftKeyAtOffice}
                    setLeyKeyAtOffice={setLeyKeyAtOffice}
                    emailCitySufix={emailCitySufix}
                  />
                )}
                {finishBookingStep === 3 && (
                  <>
                    <Step4
                      onBack={() => {
                        setFinishBookingStep(2);
                        scrollToTop();
                      }}
                      emailCitySufix={emailCitySufix}
                      onSubmit={() => {
                        scrollToTop();
                        if (choosenService === SERVICE_TYPES.Kontorsstädning) {
                          sendKontorsstadningEmail(
                            () => navigateToSuccessPage(true),
                            placeSize,
                            postNumber,
                            date,
                            time,
                            howOften,
                            firstName,
                            lastName,
                            email,
                            mobileNumber,
                            address,
                            ort,
                            notes,
                            emailCitySufix
                          );
                        } else if (
                          choosenService === SERVICE_TYPES.Flyttstädning
                        ) {
                          sendFltyystadningEmail(
                            () => navigateToSuccessPage(true),
                            placeSize,
                            postNumber,
                            date,
                            leftKeyAtOffice,
                            firstName,
                            lastName,
                            email,
                            mobileNumber,
                            address,
                            ort,
                            personNumber,
                            notes,
                            price,
                            emailCitySufix
                          );
                        } else if (
                          choosenService === SERVICE_TYPES.Storstädning
                        ) {
                          sendStorstadningEmail(
                            () => navigateToSuccessPage(true),
                            placeSize,
                            postNumber,
                            date,
                            leftKeyAtOffice,
                            firstName,
                            lastName,
                            email,
                            mobileNumber,
                            address,
                            ort,
                            personNumber,
                            notes,
                            price,
                            emailCitySufix
                          );
                        }
                      }}
                      postNumber={postNumber}
                      setPostNumber={setPostNumber}
                      firstName={firstName}
                      setFirstName={setFirstName}
                      lastName={lastName}
                      setLastName={setLastName}
                      email={email}
                      setEmail={setEmail}
                      address={address}
                      setAddress={setAddress}
                      ort={ort}
                      mobileNumber={mobileNumber}
                      setMobileNumber={setMobileNumber}
                      setOrt={setOrt}
                      notes={notes}
                      setNotes={setNotes}
                      choosenService={choosenService}
                      personNumber={personNumber}
                      setPersonNumber={setPersonNumber}
                    />

                    <div className="last-step-summary">
                      <div className="divider" />
                      <Ticket
                        // meetingType={meetingType}
                        date={date}
                        time={time}
                        step={finishBookingStep}
                        postNumber={postNumber}
                        address={address}
                        placeSize={placeSize}
                        price={price}
                        choosenService={choosenService}
                        ort={ort}
                        // additions={additions}
                        // fourSideBalcon={fourSideBalcon}
                        // twoSideBalcon={twoSideBalcon}
                      />
                    </div>
                    <div className="confirm-info info-2 white-shadow">
                      <p className="description" style={{ margin: 0 }}>
                        Genom att klicka på “Bekräfta & boka” godkänner jag
                        Bilda Groups{" "}
                        <a
                          rel="noopener noreferrer"
                          href={withPrefix(
                            "/Integritetspolicy Bilda Group.pdf"
                          )}
                          target="_blanko"
                        >
                          integritetspolicy.
                        </a>
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="right">
              <Ticket
                // meetingType={meetingType}
                date={date}
                time={time}
                step={finishBookingStep}
                postNumber={postNumber}
                address={address}
                placeSize={placeSize}
                price={price}
                choosenService={choosenService}
                ort={ort}
                // additions={additions}
                // fourSideBalcon={fourSideBalcon}
                // twoSideBalcon={twoSideBalcon}
              />
              {isSecondType(choosenService) && (
                <OpenDropdown onClick={() => setDropdownPopupVisible(true)} />
              )}
            </div>
            {/* {ticketVisible && ( */}
            <div className={"ticket" + (ticketVisible ? " active" : "")}>
              <div className="inner">
                <Ticket
                  // meetingType={meetingType}
                  date={date}
                  time={time}
                  step={finishBookingStep}
                  postNumber={postNumber}
                  address={address}
                  placeSize={placeSize}
                  price={price}
                  choosenService={choosenService}
                  ort={ort}
                  // additions={additions}
                  // fourSideBalcon={fourSideBalcon}
                  // twoSideBalcon={twoSideBalcon}
                />
                <div className="divider" />
                {isSecondType(choosenService) &&
                  (choosenService === SERVICE_TYPES.Flyttstädning ? (
                    <TicketDropdownFlyt />
                  ) : (
                    <TicketDropdownStors />
                  ))}
              </div>
            </div>
            {/* )} */}
            <div className="mobile-bottom">
              {!ticketVisible && (
                <div
                  className="see-ticket"
                  onClick={() => {
                    setTicketVisible(true);
                    scrollToTop();
                  }}
                  aria-hidden="true"
                >
                  <p>Se sammanfattning</p>
                  <BsChevronUp />
                </div>
              )}
              <div className="price-fixed">
                {ticketVisible && (
                  <SubmitButton
                    mobile
                    closeTicket
                    isValid={true}
                    text="Göm sammanfattning"
                    onClick={() => setTicketVisible(false)}
                  />
                )}
                {!ticketVisible && (
                  <>
                    <div className="price-left">
                      <h3>{parseInt(price)} kr</h3>
                      <p>efter RUT</p>
                    </div>
                    {finishBookingStep === 2 && (
                      <SubmitButton
                        mobile
                        // TODO: check
                        isValid={true}
                        text="Välj datum"
                        onClick={() => {
                          setFinishBookingStep(3);
                          scrollToTop();
                          props.setAbleToGoBack(true);
                        }}
                      />
                    )}
                    {finishBookingStep === 3 && (
                      <SubmitButton
                        mobile
                        text="Fortsätt"
                        isValid={
                          isSecondType(choosenService)
                            ? date
                            : date && time && howOften
                        }
                        onClick={() => {
                          setFinishBookingStep(4);
                          scrollToTop();
                        }}
                      />
                    )}
                    {finishBookingStep === 4 && (
                      <SubmitButton
                        mobile
                        text="Bekräfta & boka"
                        isValid={
                          firstName !== "" &&
                          lastName !== "" &&
                          email !== "" &&
                          mobileNumber !== "" &&
                          address !== "" &&
                          ort !== ""
                        }
                        onClick={() => {
                          scrollToTop();
                          if (
                            choosenService === SERVICE_TYPES.Kontorsstädning
                          ) {
                            sendKontorsstadningEmail(
                              () => navigateToSuccessPage(true),
                              placeSize,
                              postNumber,
                              date,
                              time,
                              howOften,
                              firstName,
                              lastName,
                              email,
                              mobileNumber,
                              address,
                              ort,
                              notes,
                              emailCitySufix
                            );
                          } else if (
                            choosenService === SERVICE_TYPES.Flyttstädning
                          ) {
                            sendFltyystadningEmail(
                              () => navigateToSuccessPage(true),
                              placeSize,
                              postNumber,
                              date,
                              leftKeyAtOffice,
                              firstName,
                              lastName,
                              email,
                              mobileNumber,
                              address,
                              ort,
                              personNumber,
                              notes,
                              price,
                              emailCitySufix
                            );
                          } else if (
                            choosenService === SERVICE_TYPES.Storstädning
                          ) {
                            sendStorstadningEmail(
                              () => navigateToSuccessPage(),
                              placeSize,
                              postNumber,
                              date,
                              leftKeyAtOffice,
                              firstName,
                              lastName,
                              email,
                              mobileNumber,
                              address,
                              ort,
                              personNumber,
                              notes,
                              price,
                              emailCitySufix
                            );
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {/* {popupVisible && (
					<Popup
						onClick={resetState}
						choosenService={choosenService}
						meetingType={meetingType}
					/>
				)} */}
      </div>
    </>
  );
};

export default Booking;
