import React from "react";
import "./SetHomeInfo.scss";
import { MdKeyboardBackspace } from "react-icons/md";
import { HOME_INFO } from "../../../enums/homeInfo";
import { Link } from "gatsby";

const SetHomeInfo = ({
  choosenService,
  placeSize,
  setPlaceSize,
  postNumber,
  setPostNumber,
  submit,
  emailCitySufix,
}) => {
  const onSubmit = (e) => {
    e.preventDefault();
    if (placeSize && postNumber) {
      submit();
    }
  };

  return (
    <>
      <Link to="/" className="service-back items-center md:flex hidden mb-3">
        <MdKeyboardBackspace /> <span className="ml-2">Tillbaka</span>
      </Link>
      <h1>
        {emailCitySufix === ""
          ? HOME_INFO[choosenService]?.heading
          : HOME_INFO["Linkoping"][choosenService]?.heading}
      </h1>
      <p className="step2p">
        {emailCitySufix === ""
          ? HOME_INFO[choosenService]?.description
          : HOME_INFO["Linkoping"][choosenService]?.description}
      </p>
      <h3>Fyll i boyta och postnummer:</h3>
      <hr />
      <form onSubmit={onSubmit}>
        <div className="flex">
          <div className="input-container">
            <input
              placeholder="Ange storlek"
              value={placeSize}
              onChange={(e) => setPlaceSize(e.target.value)}
              type="number"
              min="0"
            />
            <span>m2</span>
          </div>
          <div className="input-container">
            <input
              placeholder="Ange postnummer"
              value={postNumber}
              onChange={(e) => setPostNumber(e.target.value)}
              type="number"
              min="0"
            />
          </div>
        </div>
        <button type="submit" className="step2-btn">
          Beräkna mitt pris
        </button>
      </form>
    </>
  );
};

export default SetHomeInfo;
