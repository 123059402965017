import React, { useState } from "react";
import "./Dropdown.scss";
import { FiChevronDown } from "react-icons/fi";

const Dropdown = ({ text, items }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  return (
    <div className={"Dropdown" + (dropdownVisible ? " opened" : "")}>
      <div
        className="flex"
        onClick={() => setDropdownVisible((p) => !p)}
        aria-hidden="true"
      >
        <p>{text}</p>
        <FiChevronDown />
      </div>
      <div className={"dropdown-items" + (dropdownVisible ? " active" : "")}>
        <ul>
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
