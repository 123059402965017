import { SERVICE_TYPES } from "../enums/serviceTypes";
import { FlyttstadningSqareMeters, minimumPrice } from "./prices";

export const calculateSqareMeterPrice = (size, type) => {
  if (type === SERVICE_TYPES.Flyttstädning) {
    if (size <= 30) {
      return minimumPrice;
    }
    if (size > 60) {
      if (size >= 61 && size <= 84) return size * 35;
      else if (size >= 85 && size <= 120) return size * 30;
      else if (size >= 121 && size <= 150) return size * 28;
      else return size * 25;
    }
    return FlyttstadningSqareMeters[size];
  } else if (type === SERVICE_TYPES.Storstädning) {
    if (size <= 30) {
      return minimumPrice * 0.75;
    }
    if (size > 60) {
      if (size >= 61 && size <= 84) return size * 35 * 0.75;
      else if (size >= 85 && size <= 120) return size * 30 * 0.75;
      else if (size >= 121 && size <= 150) return size * 28 * 0.75;
      else return size * 25 * 0.75;
    }
    return FlyttstadningSqareMeters[size] * 0.75;
  }
};
