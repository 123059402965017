import React from "react";
import "./BackButton.scss";

const BackButton = ({ text, onClick }) => {
  return (
    <div className="BackButton">
      <div className="button-inner" aria-hidden onClick={onClick}>
        {text}
      </div>
    </div>
  );
};

export default BackButton;
